.header {
    width: 100%;
    padding-left: calc(var(--padding) * 1.5);
    padding-right: calc(var(--padding) * 1.5);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 80px;
    position: fixed;
    z-index: 1;
    margin-top: 25px;
}

.navbar {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}

.right-nav {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    gap:40px;
}

.navbar img {
    object-fit: cover;
    height: 75px;
    margin-right: 30px;
}

.left-nav {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap:40px;
}

.bars-container {

    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    width: 75px;
    height: 75px;

}
.fa-bars {
    color: black;
    font-size: 2.3rem;
    cursor: pointer;
}

body:has(.header-mobile) {
    overflow: hidden !important;
}
.header-mobile {
    width: 100vw;
    height: 100vh;
    position: absolute;
    background-color: #413669;
    z-index: 2;
    display: flex;
    /* align-items: center; */

    justify-content: center;
}

.header-mobile .bars-container {
    top: 28px;
    right: 21px;
}

.header-mobile .fa-xmark {

    font-size: 3rem;
    color: #ccc;
    transition: color 0.3s ease,background-color 0.3s ease;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.header-mobile .fa-xmark:hover {
    color: #eee;
    cursor: pointer;
}

.header-mobile .mobile-ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin-top: 150px;
    gap: 25px;
}

.header-mobile .mobile-ul li {
    
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;

}
.header-mobile .mobile-ul li a {
    color: white;
}

.header-mobile .mobile-ul li a {
    color: white;
    transition: all 0.2s ease;
}

.header-mobile .mobile-ul li a:hover {
    color: var(--primary-color)
}

/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* Responsive */


.hamburger-menu {
    position: absolute;
    /* width: 650px; */
    z-index: 75;
    top: 0;
    /* background-color: rebeccapurple; */
    background-color: #ddd;
    right: 150px;
    color: black;
    padding: calc(var(--padding) / 2);
    display: flex;
    flex-direction: row;
    box-shadow: var(--card-shadow);
    align-items: center;
    
}
.hamburger-menu .phone-header-button {
    background-color: var(--secondary-color);
    transition: all 0.2s ease;
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
    transition: box-shadow 0.3s ease;
    width: 100%;
}
.hamburger-menu .phone-header-button:hover {
    background-color: var(--button-header-hover); 
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
}

.hamburger-menu .phone-header-button:active {
    background-color: var(--button-header-active);
    box-shadow: 0 0 5px rgba(255, 255, 255, 1);
}

.header .fa-xmark {

    font-size: 3rem;
    color: #333;
    transition: color 0.3s ease,background-color 0.3s ease;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.header .fa-xmark:hover {
    color: black;
    cursor: pointer;
}

.hamburger-menu ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    color: black;
    width: fit-content;
    width: 100%;
}
.hamburger-menu ul .text-link {

    color: black;
    padding: var(--padding);
    display: flex;
    align-items: center;
    justify-content: center;

}

.hamburger-menu ul .text-link a {
    color: black;
    transition: all 0.2s ease;
}
.hamburger-menu ul .text-link a:hover {
    color: var(--primary-color)
}

.contact-us {
    padding: var(--padding);
    background-color: var(--card-lighter-background);
    color: white;
    width: 90%;
    max-width: 350px;

}

.contact-us a {
    color: var(--primary-color);
    transition: all 0.2s ease;
    
}

.contact-us a:hover {
    filter: brightness(1.2);
}

@media screen and (max-width: 1000px) {
   .navbar {
    justify-content: space-between;
    position: relative;
   }
   .hamburger-menu {
        top: 100px;
        right: 10px;
   }
   .hamburger-menu ul {
    gap: 50px;
   }

}   

@media screen and (max-width: 850px) {

    .hamburger-menu {
         top: 100px;
         right: 10px;
    }
    .hamburger-menu ul {
     gap: 30px;
    }
 }   

 @media screen and (max-width: 750px) {


    .navbar {
     justify-content: space-between;
     position: relative;
    }
    .hamburger-menu {
         top: 100px;
         right: 10px;

    }
    .hamburger-menu ul {
     gap: 50px;
    }

    .bars-container {
        position: absolute;
        right: 0px;
        z-index: 5;
    }

 }   


 
