.home {
  height: fit-content;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.content {
  position: absolute;
  right: 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  gap: 450px;
}

.home-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.home-content .title {
  font-size: 7.5rem;
}

.home-content .description {
  color: white;
  width: 500px;
  font-size: 1.09rem;
}

.home-button {
  background: linear-gradient(
    to right,
    #63a3ff,
    #b6dcff,
    #72beff,
    #63a3ff,
    #b6dcff,
    #72beff
  );
  background-size: 300%;
  background-position: left;
  cursor: pointer;
  border-radius: calc(4 * var(--border-radius));
  padding: calc(var(--padding) / 1.4);
  padding-inline: calc(var(--padding) * 2.7);
  color: black;
  font-size: 1.09rem;
  width: 40%;
  text-align: center;
  border: 1px solid #63a3ff;
  transition: border-color 0.4s ease 0s;
  transition: background-position 0.5s ease-in-out;
  animation: changeColor 15s infinite;
}

@keyframes changeColor {
  0% {
    background-position: left;
  }
  25% {
    background-position: right;
  }

  50% {
    background-position: left;
  }
  75% {
    background-position: right;
  }
  100% {
    background-position: left;
  }
}

.gradient-text {
  background: linear-gradient(
    to right,
    #63a3ff,
    #b6dcff,
    #72beff,
    #63a3ff,
    #b6dcff,
    #72beff
  );
  background-size: 300%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  animation: changeColor 15s infinite;
  transition: background-position 0.5s ease-in-out;
}

.app-demo {
  color: #63a3ff;
  font-size: 1.608rem;
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
  justify-content: center;
}

.app-demo video {
  width: 75%;
  border-radius: var(--border-radius);
}

/* ///////////////////////////////////////////////////////////////////////////////////////////////// */

.cards-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
  color: white;
  margin-bottom: 250px;
}
.cards {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 600px;
  gap: 50px;
  max-width: 1500px;
}

.card-container {
  flex-basis: 25%;
}
.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80%;
  /* background-color: var(--card-background-color); */
  background-color: #1e1835;
  border-radius: calc(var(--border-radius) * 2);
  padding: calc(var(--padding) * 1.5);
  box-shadow: var(--card-shadow);
  /* border: 1px solid var(--primary-color); */
  gap: 20px;
  align-items: center;
  justify-content: center;
  color: white;
}

.text-area .description {
  color: var(--description-color);
}
.card img {
  width: 100%;
  /* height: 300px; */
  /* object-fit: cover; */
  border-radius: var(--border-radius);
}

.card-button {
  background: linear-gradient(
    to right,
    #63a3ff,
    #b6dcff,
    #72beff,
    #63a3ff,
    #b6dcff,
    #72beff
  );
  background-size: 300%;
  background-position: left;
  cursor: pointer;
  border-radius: calc(4 * var(--border-radius));
  padding: calc(var(--padding) / 1.4);
  padding-inline: calc(var(--padding) * 1.608);
  color: black;
  font-size: 1.09rem;
  text-align: center;
  border: 1px solid #63a3ff;
  transition: border-color 0.4s ease 0s;
  transition: background-position 0.5s ease-in-out;
  animation: changeColor 15s infinite;
}

.card-button:hover {
  background-color: var(--button-header-hover);
}

.card-button:active {
  background-color: var(--button-header-active);
}

/* ////////////////////////////////////////////////////////////////////////////////////////////////// */

.about-us-container {
  width: 100%;
  display: flex;
  padding-inline: calc(10 * var(--padding));
}
.about-us-content {
  width: 50%;

  display: flex;
  flex-direction: column;
}

.about-us-content .title {
  color: var(--primary-color);
  font-size: 2.5rem;
}
.about-us-content .description {
  color: var(--description-color);
  font-size: 1.24rem;
  margin-bottom: 25px;
}
.user-facts {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.user-info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.user-info .number {
  color: var(--primary-color);
  font-size: 3rem;
  font-weight: 700;
}

.user-info .text-info {
  margin-top: -7px;
  opacity: 0.8;
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////////// */

.pricing {
  width: 100%;
  /* height: 600px; */
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 75px;
}

.lower-part,
.upper-part {
  width: 100%;
  display: flex;
}

.upper-part {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.upper-part .pricing-description {
  font-size: 0.9rem;
  opacity: 0.8;
}

.pricing .lower-part {
  gap: 30px;
  display: grid;
  grid-template-columns: repeat(2, 25%);
  justify-content: center;
  height: auto;
  margin-bottom: 50px;
}

.price-card {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  flex-direction: column;
  border: 3px solid var(--primary-color);
  padding: calc(var(--padding) * 2);
  border-radius: var(--border-radius);
  box-shadow: var(--card-shadow);
  background-color: var(--card-background);
  height: 100%;
  position: relative;
}

.price-card .discount {
  position: absolute;
  top: -15px;
  left: 32%;
  border-radius: calc(var(--border-radius) * 10);
  background-color: #ffd5de;
  color: #ef456a;
  padding: calc(var(--padding) / 2);
  font-size: 0.9rem;
  font-weight: 700;
}
.price-card div .price-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.price-top .full-price {
  background-color: #ddd;
  padding: calc(var(--padding) / 2);
  padding-inline: var(--padding);
  border-radius: calc(var(--border-radius) * 4);
  font-size: 0.8rem;
  text-decoration: line-through;
  font-weight: 700;
}
.price-card-title {
  color: var(--primary-color);
  font-size: 1.1rem;
}

.price-card-description {
  color: var(--description-color);
  font-size: 0.8rem;
}

.price-card-cost {
  color: white;
  font-weight: 700;
  font-size: 1.6rem;
}

.price-card-benefits,
.price-card-not-avaible {
  display: flex;
  flex-direction: column;
  gap: 10px;
  list-style: none;
  font-size: 0.8rem;
  color: #d0d0d0;
}

.price-card-not-avaible li {
  color: #808080;
  vertical-align: middle;
}
.price-card-not-avaible li i {
  color: var(--error-color);
  margin-right: 5px;
  font-size: 1rem;
}
.price-card-benefits li i {
  margin-right: 10px;
  color: var(--primary-color);
  font-weight: 700;
}
.price-button {
  transition: all 0.2s ease;
  background-color: var(--card-lighter-background);
  color: white;
  cursor: pointer;
  box-shadow: var(--card-shadow);
  margin-top: auto;
  border: 1px solid var(--primary-color);
  transition: all 0.2s ease;
}

.price-button:hover {
  filter: brightness(1.2);
}

.price-button:active {
  filter: brightness(0.9);
}

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */

.reviews {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 600px;
  gap: 50px;
  max-width: 1400px;
}

.review-container {
  width: 90%;
}
.review {
  display: flex;
  /* align-items: center; */
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  padding: var(--padding);
  border-radius: var(--border-radius);
  border: 1px solid var(--primary-color);
  height: 430px;
  box-shadow: var(--card-shadow);
  background-color: rgba(247, 247, 251, 0.1);
  color: white;
}

.review .stars {
  color: var(--primary-color);
}
.review-user-info img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

/* //////////////////////////////////////////////////////////////////////////////////////////////////////////// */

.sample {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 700px;
  gap: 25px;
}

.sample .text-container {
  width: 35%;
  display: flex;
  align-items: center;
}
.sample .left-side {
  align-items: flex-start;
  gap: 10px;
  width: 100%;
}

.sample-description {
  color: var(--description-color);
}

.sample-button {
  background-color: var(--primary-color);
  color: white;
  padding-left: calc(var(--padding) * 2);
  padding-right: calc(var(--padding) * 2);
  box-shadow: var(--card-shadow);
}

.sample-button:hover {
  cursor: pointer;
  background-color: var(--button-hover);
}

.sample-button:active {
  background-color: var(--button-active);
}
.sample .right-side {
  margin-left: 0;
  height: 100%;
  flex-basis: auto;
}

.image-container {
  height: 100%;
  width: 40%;
}
.sample .right-side img {
  width: 90%;
  border-radius: var(--border-radius);
  box-shadow: var(--card-shadow);
}

/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////// */

/* FAQ */

.faq-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 400px;
}

.question-container {
  color: white;
}
#faq-home {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 25px;
}

#faq-home h2 {
  color: white;
}
#faq-home .faq-input {
  width: 40%;
}
#faq-home .faq-input div {
  border-radius: 25px;
  color: white;
}
#faq-home .faq-input div fieldset {
  border-color: rgba(255, 255, 255, 0.7);
  transition: all 0.2s ease;
}

#faq-home .faq-input div:hover fieldset {
  border-color: rgba(255, 255, 255, 1);
}

.questions-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 25px;
}
/* PRIVACY AND TERMS */

.privacy,
.terms {
  width: 100%;
  /* height: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
}

.privacy > div,
.terms > div {
  width: 52%;
  text-align: left;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 25px;
}

.privacy > div p:last-child {
  margin-bottom: 50px;
}

.privacy a,
.terms a {
  color: var(--primary-color);
}

.read-more {
  font-size: 0.9rem;
  color: var(--description-color);
  cursor: pointer;
}

.skillify-description > div > div {
  background-color: var(--card-background);
  color: white;
  text-align: center;
  border: 1px solid var(--primary-color);
}


/* TRANSALSATE PAGEEE */

.VIpgJd-ZVi9od-ORHb-OEVmcd {
  display: none;
}

body {
  top: 0 !important;
}

.translate-page {
  color: white;
  width: 275px;
  height: 175px;
  border-bottom-right-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
  padding: var(--padding);
  background-color: var(--card-lighter-background);
  position: absolute;
  z-index: 10;
  top: 0;
  padding-top: 25px;
  right: 100px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  box-shadow: var(--card-shadow);
}

.translate-page .icon-profile {
  top: -3px;
}

.confirm-language {
  padding: var(--padding);
  background-color: var(--card-background);
  color: white;
  display: flex;
  flex-direction: column;
  gap: 10px; 
  text-align: center;
  align-items: center;
}
.confirm-language p {
  color: var(--description-color);
}
.language-button {
  cursor: pointer;
  border: 1px solid var(--primary-color);
  background-color: var(--card-lighter-background);
  color: white;
  width: 50%;
  transition: all 0.2s ease;
}
.language-button:hover {
  filter: brightness(1.2);
}
.cancel-language-button {
  background-color: var(--error-color);
}

/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */

/* RESPONSIVE */

@media screen and (max-width: 1600px) {
  .reviews {
    height: 500px;
  }
  .review {
    height: 300px;
  }
}

@media screen and (max-width: 1250px) {
  .review {
    height: 350px;
  }
}

@media screen and (max-width: 999px) {
  .content {
    right: 25%;
  }

  .app-demo video {
    width: 150%;
  }
  .cards-container {
    margin-bottom: 75px;
  }
  .about-us-container {
    align-items: center;
    justify-content: center;
    padding-inline: 0;
  }
  .about-us-content {
    width: 70%;
  }
  .cards {
    display: grid;
    grid-template-columns: repeat(2, 30%);
    height: fit-content;
    margin-top: 50px;
  }

  .card-container:last-child {
    grid-column: span 2;
  }

  .sample {
    flex-direction: column;
    height: fit-content;
    margin-top: 50px;
  }
  .sample .text-container {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sample .left-side {
    align-items: center;
  }
  .image-container {
    width: 90%;
  }
  .reviews {
    flex-direction: column;
    height: fit-content;
    margin-top: 50px;
  }

  .review-container .review {
    height: fit-content;
  }

  .pricing {
    margin-top: 50px;
  }
  .pricing .lower-part {
    grid-template-columns: repeat(2, 40%);
  }
}

@media screen and (max-width: 750px) {
  .content {
    right: 25%;
  }
  .background {
    background-position: center !important;
  }
  .home-button {
    width: fit-content;
  }
  .home-content .title {
    font-size: 3.75rem;
  }

  .home-content .description {
    color: white;
    width: 400px;
    font-size: 1.09rem;
  }
}

@media screen and (max-width: 576px) {
  .home-content .title {
    font-size: 3.75rem;
  }

  .home-content .description {
    color: white;
    width: 300px;
    font-size: 1.09rem;
  }
  .cards {
    display: flex;
    flex-direction: column;
    width: 75%;
  }

  .main-input {
    width: 90%;
  }
  .main-input input,
  .main-input .main-button {
    font-size: 0.9rem;
  }

  .cards-container h1 {
    text-align: center;
  }
  .right-side .main-quote {
    font-size: 1.5rem;
    width: 90%;
  }
  .user-facts {
    display: grid;
    grid-template-columns: repeat(2, auto);
  }

  .user-facts .user-info:first-child {
    grid-column: span 2;
  }
  .pricing {
    height: fit-content;
  }
  .pricing .lower-part {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    margin-bottom: 50px;
  }
  .lower-part .price-card {
    width: 75%;
  }
  .pricing-container {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .privacy > div,
  .terms > div {
    width: 75%;
  }
  .faq-container {
    margin-top: 50px;
  }
  #faq-home .faq-input {
    width: 70%;
  }
}

@media screen and (max-width: 350px) {
  .home-content .description {
    width: 200px;
  }
}

/* Written By Admin. */
.primary-btn {
  color: #fff;
  background-color: var(--btn-primary);
  padding: 20px 40px;
  box-shadow: var(--card-shadow);
  border-radius: var(--btn-border-radius);
}

.primary-link {
  z-index: 100;
  position: relative;
  margin-top: 15px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
}
.img-fluid {
  width: 100%;
}
.d--flex {
  display: flex;
}
.d--none {
  display: none;
}
.position--absolute {
  position: absolute;
}
.position--relative {
  position: relative;
}
.align--center {
  align-items: center;
}
.flex--column {
  flex-direction: column;
}
.flex--row {
  flex-direction: row;
}
.align--end {
  align-items: flex-end;
}
.justify--center {
  justify-content: center;
}
.justify--between {
  justify-content: space-between;
}
.justify--end {
  justify-content: flex-end;
}
.bordered {
  border: 1px dashed black;
}
.mb-12 {
  margin-bottom: 12px;
}
.mt-12 {
  margin-top: 12px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-50 {
  margin-top: 50px;
}
.mb-50 {
  margin-bottom: 50px;
}
.m-lr-100 {
  margin-left: 100px;
  margin-right: 100px;
}
.m-tb-100 {
  margin-top: 100px;
  margin-bottom: 100px;
}
.w-50 {
  width: 50%;
}
.w-100 {
  width: 100%;
}
.bg-white {
  background: #fff;
}
.gap-60 {
  column-gap: calc(60px * 2);
}
.gapY-30 {
  column-gap: 30px;
}
.gapY-20 {
  column-gap: 20px;
}
.rounded {
  border-radius: 20px;
}
.rounded-lg {
  border-radius: 40px;
}
.rounded-sm {
  border-radius: 10px;
}
.about--section {
  margin-top: 18rem;
}
.about-section-left h3 {
  font-size: 2rem;
  font-weight: 700;
  color: #1f1f1f;
  text-transform: capitalize;
}
.about-section-left p {
  color: var(--description-color-dark);
  font-size: 22px;
  line-height: 30px;
  font-family: "inter", sans-serif;
  margin-top: 8px;
  margin-bottom: 22px;
  text-align: justify;
}
.about-section-left a {
  background: var(--btn-primary);
  color: #fff;
  width: fit-content;
  padding: 20px 40px;
  border-radius: var(--btn-border-radius);
  font-size: 18px;
  line-height: 20px;
  box-shadow: var(--card-shadow);
}
.cat-card {
  width: calc(100% / 3 - 20px);
  border: 1px solid var(--primary-color);
  border-radius: 20px;
  box-shadow: var(--card-shadow);
}
.cat-card-content {
  padding: 40px 30px 45px 20px;
}
.card-btn-wrapper {
  margin-top: 40px;
}
.cat--section {
  margin-top: 100px;
  margin-bottom: 100px;
}
.cat-card-text p {
  font-size: 18px;
  line-height: 24px;
  word-spacing: 0.3;
  color: var(--description-color-dark);
}
.cat-card-text h3 {
  font-size: 32px;
  line-height: 45px;
  font-weight: 600;
}
.cat-card-img img {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.app-footer {
  padding: 60px 0 30px 0;
  background: url("../../src/images/footer-bg.png") no-repeat center;
  background-size: cover;
  display: flex;
  justify-content: center;
  width: 100%;
}
.footer-content {
  /* max-width: 740px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
li {
  list-style: none;
}
.footer-link a {
  font-size: 18px;
  line-height: 38px;
  padding: 2px;
}
@media (min-width: 650px) {
  .about-text {
    margin-top: 30px;
  }
}
.about-text p {
  color: var(--description-color-dark);
  font-size: 18px;
  line-height: 30px;
  text-align: center;
}
.social-icons {
  margin-top: 40px;
}
.social-icons ul {
  column-gap: 25px;
}
.social-icons ul a img {
  transition: 0.4s all !important;
}
.social-icons ul a:hover img {
  transform: scale(1.3);
}
.copyright-text {
  margin-top: 100px;
}
.copyright-text p {
  font-size: 14px;
  line-height: auto;
  color: var(--description-color-dark);
}
.copyright-text p a {
  color: var(--primary-color);
  font-weight: 500;
  text-decoration: underline;
  text-underline-offset: 2px;
}

/* FAQ's */
section.faqs-wrapper {
  background: #f7f7fb;
  padding: 60px 40px 70px;
  position: relative;
}
.faqs-wrapper .dotsEffect {
  position: absolute;
  left: -30px;
  z-index: -1;
  bottom: -30px;
}
/* .faqs-wrapper .Ellipse-Effect {
  position: absolute;
  transform: rotate(180deg);
  top: 0;
  right: -100px;
  z-index: -1;
} */
.faq-heading h3 {
  font-size: 38px;
  font-weight: 700;
  line-height: 45px;
  text-align: center;
}
.faqsLeft {
  width: calc(100vw / 2 - 30px);
}
.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY[aria-expanded="false"] {
  padding: 40px 30px !important;
  align-items: center !important;
  transition: 0.1s all !important;
}
.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY[aria-expanded="true"] {
  padding: 40px 30px 10px !important;
  transition: 0.1s all !important;
}
.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY
  .styles_icon-wrapper__2cftw {
  position: static !important;
}
.styles_faq-row-wrapper__3vA1D {
  background: none !important;
  border: none !important;
}

section.faq-row {
  margin-bottom: 30px !important;
  background: #ddd !important;
  border-radius: 20px !important;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.01) !important;
  border: none !important;
}
section.faq-row .row-content {
  padding: 0 30px !important;
}
section.faq-row .row-content[aria-expanded="true"] {
  padding: 0 30px 60px !important;
  transition: 0.1s all !important;
  font-size: 18px !important;
  margin-bottom: 1rem !important;
}
.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-content__QOGZd
  .styles_row-content-text__2sgAB {
  color: var(--description-color-light) !important;
}
.row-title-text {
  font-size: 22px;
  line-height: 28px;
  font-weight: 500;
  color: var(--primary-dark);
}
.Effect-AboutSection {
  position: absolute;
  left: 0;
  z-index: -1;
  user-select: none !important;
}
.Effect-AboutSection-2 {
  position: absolute;
  left: 0;
  top: 100vh;
  z-index: -1;
  user-select: none !important;
}
.Effect-CatSection {
  position: absolute;
  right: 0;
  transform: rotate(180deg);
  z-index: -1;
  user-select: none !important;
}

/* RESPOSIVE STYLE */
@media only screen and (max-width: 1200px) {
  .about-section-content {
    row-gap: 30px;
  }
  .about-section-content {
    column-gap: 30px;
  }
  .about--section {
    margin-left: 60px;
    margin-right: 60px;
  }
  .about-section-left p {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 22px;
  }

  .cat-card-text h3 {
    font-size: 28px;
    line-height: 40px;
  }
  .cat-card-text p {
    font-size: 16px;
  }
  .cat--section {
    margin: 80px 60px;
  }
  section.faqs-wrapper {
    padding: 40px;
    margin: 80px 60px;
  }
  .row-title-text {
    font-size: 20px;
    line-height: 26px;
  }
  .app-footer {
    margin: 0 80px;
  }
}

/* =================== */

@media only screen and (max-width: 1024px) {
  .about--section {
    margin-left: 40px;
    margin-right: 40px;
  }
  .about-section-content {
    flex-direction: column;
  }
  .about-section-left,
  .about-section-right {
    width: 100%;
  }
  .cat-section-content {
    flex-direction: column;
    row-gap: 30px;
  }
  .cat-section-content .cat-card {
    width: 100%;
  }
  .cat--section {
    margin: 60px 40px;
  }
  section.faqs-wrapper {
    margin: 60px 40px;
  }
  .faqs-container {
    margin-top: 30px;
    flex-direction: column;
  }
  .faqsLeft {
    width: 100%;
  }
  .app-footer {
    margin: 0;
    margin-top: 40px;
    padding: 20px 40px;
  }
  .footer-link a {
    font-size: 18px;
    line-height: 28px;
  }
  nav.footer-nav {
    justify-content: center;
    margin: 0 !important;
  }
  .social-icons {
    margin-top: 20px;
  }
  .copyright-text {
    margin-top: 40px;
  }
  /* =================== */
  @media only screen and (max-width: 767px) {
    .Effect-AboutSection, .Effect-AboutSection-2 {
      width: 100%;
    }
    .about--section {
      margin-left: 20px;
      margin-right: 20px;
    }
    .about--section {
      margin-top: 60px;
    }
    .about-section-left p {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 16px;
    }
    .about-section-right img {
      height: auto !important;
    }
    .cat--section {
      margin: 40px 20px;
    }
    .cat-card-content {
      padding-top: 20px;
    }
    .cat-card-text h3 {
      font-size: 24px;
      line-height: 32px;
    }
    .cat-card-text p {
      font-size: 16px;
      line-height: 18px;
      margin-top: 4px;
    }
    section.faqs-wrapper {
      margin: 40px 20px;
      padding: 25px;
    }
    .faq-heading h3 {
      font-size: 26px;
      line-height: 32px;
    }
    .styles_faq-row-wrapper__3vA1D
      .styles_row-body__1NvUo
      .styles_faq-row__2YF3c
      .styles_row-title__1YiiY[aria-expanded="false"] {
      padding: 20px !important;
      align-items: center !important;
      transition: 0.1s all !important;
    }
    .styles_faq-row-wrapper__3vA1D
      .styles_row-body__1NvUo
      .styles_faq-row__2YF3c
      .styles_row-title__1YiiY[aria-expanded="true"] {
      padding: 20px 20px 10px !important;
      transition: 0.1s all !important;
    }
    section.faq-row .row-content[aria-expanded="true"] {
      padding: 0 20px 40px !important;
    }
    section.faq-row .row-content {
      padding: 0 20px !important;
    }
    .row-title-text {
      font-size: 18px;
      line-height: 22px;
    }
    section.faq-row {
      margin-bottom: 20px !important;
    }
    .app-footer {
      padding: 20px 10px;
    }
    .footer-link {
      column-gap: 0;
    }
    .footer-link a {
      font-size: 14px;
      line-height: 18px;
      width: 40%;
    }
    .about-text p {
      font-size: 14px;
      line-height: 22px;
    }
    .footer-content .about-text {
      margin: 20px 10px;
    }
    .footer-logo {
      margin-bottom: 20px;
    }
  }
}

/* Dark Version Code */
body {
  background: #00051e;
}
li,
a,
span,
.about-section-left h3,
.cat-card-text h3,
h3,
.main-heading {
  color: #fff;
}
.cat-card-text p,
.about-text p,
.about-section-left p {
  color: #f0f0f0;
}
.cat-card {
  background: transparent;
}

.about-section-left a,
.primary-btn {
  background: #0066ff;
  transition: all 0.2s ease;
}

.about-section-left a:hover,
.primary-btn:hover {
    filter: brightness(1.2);
}

.about-section-left a:active,
.primary-btn:active {
  filter: brightness(0.9);
} 
section.faqs-wrapper {
  background: rgba(247, 247, 251, 0.1);
}

/* ========= */
header nav ul a {
  font-size: 16px;
  font-weight: 500;
}
.NavMenuBtn {
  display: none;
}
@media (max-width: 750px) {
  .NavMenuBtn {
    display: flex;
  }
}

@media (max-width: 450px) {
  
  nav div.close {
    display: none;
  }
  header nav ul.flex-center {
    flex-direction: column;
  }
  header nav ul li {
    margin: 1rem 0;
    width: 100%;
  }
  nav div.open {
    position: absolute;
    top: 90px;
    left: 0;
    background: rgb(0, 5, 30);
    background: linear-gradient(
      90deg,
      rgba(0, 5, 30, 1) 0%,
      rgba(38, 14, 76, 1) 50%,
      rgba(66, 22, 70, 1) 100%
    );
    border: 1px solid var(--primary-color);
    border-radius: 15px;
    padding: 2vw 5vw;
    width: 100%;
    display: flex;
  }
}
