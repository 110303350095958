.left-section {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.choose-plan {
    width: 100%;
    display: flex;
    gap: 5%;

}

.choose-plan .choose-lesson, .choose-plan .choose-tool{
    color: white;
    background-color: var(--card-background);
    padding: var(--padding);
    border-radius: var(--border-radius);
    border: 1px solid var(--primary-color);
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.choose-plan p {
    cursor: pointer;
    transition: all 0.2s ease;
}
.choose-plan p:hover {
    color: var(--primary-color);
    /* text-decoration: underline; */
    font-size: 1.1rem;
}

.choose-plan .active {
    color: var(--primary-color);
    /* text-decoration: underline; */
    font-size: 1.1rem;
}

.save-title-container {
    height: 80%;
    display: flex;
    width: 100%;
    gap: 5%;
}

.save-title-container .save-lesson-container, 
.save-title-container .save-tool-container {
    display: flex;
    flex-direction: column;
    color: white;
    background-color: var(--card-background);
    
    border-radius: var(--border-radius);
    border: 1px solid var(--primary-color);
    list-style: none;
    width: 50%;
    align-items: center;
    overflow-y: auto;
}


.save-title-container li {
    cursor: pointer;
    transition: all 0.2s ease;
    text-align: center;
    width: 100%;
    border-bottom: 1px solid var(--primary-color);
    padding: var(--padding);
    
}

.save-title-container li:hover {
    color: var(--primary-color);
    font-size: 1.1rem;
}

.save-title-container .active {
    color: var(--primary-color);
    font-size: 1.1rem;
}

.delete-lesson {
    background-color: var(--error-color);
}

.delete-button {
    color: var(--error-color);
}