.footer {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap:35px;
  
}

.footer img {
    height: 120px;
    margin-top: 20px;
}

.social-media {
    color:white;
    width:20%;
    display: flex;
    justify-content: space-evenly;
    font-size: 1.5rem;
}

.social-media a {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-links {
    display: flex;
    gap: 35px;
    color: white;
}

.footer-links a {
    color: white;
    opacity: 0.6;
    transition: opacity 0.2s ease;
    position: relative
}


.footer-links a:hover {
    opacity: 1;
}
.footer-links a::before {
    content: "";
    position: absolute;
    bottom: -2px; /* Set position below the link text */
    left: 0;
    width: 100%;
    height: 2px;
    background-color: white;
    transform: scaleX(0); /* Start with a width of 0 */
    transform-origin: left;
    transition: transform 0.2s ease;
}

.footer-links a:hover::before {
    transform: scaleX(1); /* Scale up the width on hover */
}

.rights {
    color: white;
    opacity: 0.4;
    font-size: 0.8rem;
}

@media screen and (max-width:1000px) {
    .social-media {
        width: 25%;
    }
    
}

@media screen and (max-width:576px) {
    .social-media {
        width: 50%;
    }

    .footer-links {
        gap: 15px;
    }
}